import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import { getAppSetting } from "../../config/AppSettings";
import { KatalMonitoringDriver, KatalMonitoringDriverOptions } from '@amzn/katal-monitoring-aws-driver';
import { MetricType } from "@amzn/katal-monitoring";
import {
    DRIVER_LOG_TO_CONSOLE,
    CLIENT_METRICS_ENDPOINT,
    SITE,
    SERVICE_NAME,
    BATCH_TIMEOUT_DURATION_MILLISECONDS,
    LATENCY,
    REPORT_ITEM_EDIT_TIME,
    REPORT_ITEMS_PAGE
} from "./Constants";

class MetricsDriver {
    private static instance: MetricsDriver;
    private driver: KatalMonitoringDriver | null = null;
    private midwayToken: string | null = null;
    private driverConfigs: KatalMonitoringDriverOptions | null = null;

    // Create a single instance and reuse instance on all subsequent calls
    constructor(driverConfigs: KatalMonitoringDriverOptions | null = null) {
        if (!MetricsDriver.instance) {
            MetricsDriver.instance = this;

            // If no driverConfigs defined, use default values
            if(driverConfigs === null) {
                this.driverConfigs = {
                    url: `${getAppSetting('apiUrl')}${CLIENT_METRICS_ENDPOINT}`,
                    logToConsole: DRIVER_LOG_TO_CONSOLE,
                    batchTimeoutDuration: BATCH_TIMEOUT_DURATION_MILLISECONDS
                }
            }
        }

        // Allow injecting KatalMonitoringDriverOptions
        if (driverConfigs !== null) {
            MetricsDriver.instance.driverConfigs = driverConfigs;
            if (MetricsDriver.instance.driverConfigs.url === undefined) {
                MetricsDriver.instance.driverConfigs.url = `${getAppSetting('apiUrl')}${CLIENT_METRICS_ENDPOINT}`;
            }
            // Reset driver instance so a new one can be created with new configs
            MetricsDriver.instance.driver = null;
        }

        return MetricsDriver.instance;
    }

    private createDriver(driverConfigs: KatalMonitoringDriverOptions) {
        return new KatalMonitoringDriver(driverConfigs);
    }

    private getDriver(newToken: string) {
        // In the event of a new token or non-existent driver, create a new driver instance
        if (newToken !== this.midwayToken || this.driver === null) {
            this.midwayToken = newToken;
            this.driver = this.createDriver({
                ...this.driverConfigs,
                headers: {"Authorization": `Bearer ${this.midwayToken}`}
            });
        }
        return this.driver;
    }

    async publishMetric(metricName: string, pageName: string, unit: MetricType, value: number | string) {
        const newToken = await getMidwayJwtToken();
        try {
            // Call publishMetric function on the driver which sends XHRHttpRequest to the API Gateway endpoint with a MetricPayload
            this.getDriver(newToken).publishMetric({
                    site: SITE,
                    serviceName: SERVICE_NAME,
                    metricKey: metricName,
                    value: value,
                    metricType: unit,
                    timestamp: Date.now(),
                    dimensions: {
                        "PageName": pageName
                    },
                    additionalInfo: {},
                })
        }
        catch (error) {
            console.log(`Failed to publish metric for ${metricName}`)
            console.log(error);
        }
    }

    // Helper functions for sending metrics to MetricsDriver

    // Send Timer metric for page load latencies
    sendPageLatency(latency: number, pageName: string) {
        if (latency > 0) {
            this.publishMetric(LATENCY, pageName, MetricType.TIMER, latency).then();
        }
    }

    // Send Timer metric for individual report item edit duration
    sendReportItemEditTime(editTime: number) {
        if (editTime > 0) {
            this.publishMetric(REPORT_ITEM_EDIT_TIME, REPORT_ITEMS_PAGE, MetricType.TIMER, editTime).then();
        }
    }

    // Send Counter metric for each use of UI functionalities
    sendUseFunctionality(functionality: string | undefined, pageName: string) {
        if (functionality) {
            this.publishMetric(functionality, pageName, MetricType.COUNTER, 1).then();
        }
    }
}

export default MetricsDriver;

