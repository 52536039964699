import { useEffect, useState, useCallback } from 'react';
import {useHistory, useParams} from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import {Report, ReportItem, FondueApi, DisableReportItemRequest} from '../../fondue-api/generated-src';
import { States } from "../../common/States";
import { ButtonDropdown, ButtonDropdownProps } from '@amzn/awsui-components-react';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import {CollectionPreferencesProps} from "@amzn/awsui-components-react/polaris/collection-preferences";
import {useData} from '../DataContext';

import {
    Box,
    CollectionPreferences,
    FormField,
    Header,
    Table,
    TableProps,
    SpaceBetween,
    Badge, Alert, ProgressBar, Textarea
} from '@amzn/awsui-components-react/polaris';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import * as constants from '../../common/constants';


export interface ReportTableProps {
    setState: (x: States) => void;
    state: States;
    updatingReportItem: string;
    setUpdatingReportItem: (x: any) => void;
    refreshTable: boolean;
    setRefreshTable: (x: boolean) => void;
    editSelectReportItem: string;
    setEditSelectReportItem: (x: string) => void;
    report: Report;
    setReportItem: (reportItem: ReportItem) => void;
    setAddDisabled: (x: boolean) => void;
    setEditDisabled: (x: boolean) => void;
    setAddEditInfoDisabled: (x: boolean) => void;
    setSplitPanelOpen: (x: boolean) => void;
    setSplitPanelTitle: (x: string) => void;
    setAllReportItems: (reportItems: ReportItem[]) => void;
}


export default function ReportItemsTable({setState, state, updatingReportItem, setUpdatingReportItem, refreshTable, setRefreshTable, report,
                                          editSelectReportItem, setEditSelectReportItem, setReportItem, setAddDisabled, setEditDisabled, setAddEditInfoDisabled, setSplitPanelOpen, setSplitPanelTitle,
                                          setAllReportItems}: ReportTableProps) {
    Object.freeze(Object.prototype);
    const { report_id }= useParams<{report_id: string}>();
    const [allItems, setAllItems] = useState<ReportItem[]>();
    const [selectedItem, setSelectedItem] = useState<ReportItem[]>();

    const [inputValue, setInputValue] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState("");

    const [saveDisabled, setSaveDisabled] = useState(true);

    const [initialItems, setInitialItems] = useState<number[]>();
    const [selectedItems, setSelectedItems] = useState<ReportItem[]>([]);

    const [pageSize, setPageSize] = useState(20);
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: pageSize,
        wrapLines: true,
        visibleContent: ["metric_number", "name", "type", "description", "actions"]
    });
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteMetricReason, setDeleteMetricReason] = useState("");
    const [deleteProgressPercentage, setDeleteProgressPercentage] = useState(0);
    const [deleteProgressInfo, setDeleteProgressInfo] = useState("");
    const [deleteReasonTextInvalid, setDeleteReasonTextInvalid] = useState(false);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

    const [actionEllipsesDisabled, setActionEllipsesDisabled] = useState(false);

    // PreSelectedItem used by DuplicateReportItem right now, to pre select newly duplicated item by default
    const {preSelectedItem, setPreSelectedItem} = useData();
    const {setReportItemEditStart} = useData();

    // Disable Add Item button by default before Report Items Page loads
    const [disableAddItem, setDisableAddItem] = useState(true);

    const FondueApi = FondueApiFactory();

    function getItems(){
        var curItems:ReportItem[] = []
        if(allItems){
            allItems.forEach(function(item){
                curItems.push(item);
            })
        }
        return curItems;
    }

    function moveMetricUp(){
        if(selectedItems && selectedItems.length >= 1 && selectedItems[0].metric_number && allItems){
            var curItems = getItems();
            // Order so that top moves first so the selected items wont swap with each other
            var orderedItems: ReportItem[] = []
            selectedItems.forEach(function(item){
               if(item.metric_number)
                   orderedItems.push(item)
            });
            orderedItems.sort((a,b) => {
               if(a.metric_number && b.metric_number){
                   return a.metric_number - b.metric_number;
               }
               return 0;
            });

            orderedItems.forEach(function(item){
                if(item && item.metric_number && item.metric_number > 1){
                    var toFind = item.metric_number-1;
                    curItems.forEach(function(curItem){
                        if(curItem && curItem.metric_number && curItem.metric_number == toFind){
                            curItem.metric_number = item.metric_number;
                        }
                    })
                    item.metric_number--;
                }
            });
            setAllItems(curItems);
            setModalVisible(false);
            setSaveDisabled(false);
        }
    }

    function moveMetricDown(){
        if(selectedItems && selectedItems.length >= 1 && selectedItems[0].metric_number && allItems){
            var curItems = getItems();
            // Order so that bottom moves first so the selected items wont swap with each other
            var orderedItems: ReportItem[] = []
            selectedItems.forEach(function(item){
               if(item.metric_number)
                   orderedItems.push(item)
            });
            orderedItems.sort((a,b) => {
               if(a.metric_number && b.metric_number){
                   return b.metric_number - a.metric_number;
               }
               return 0;
            });

            orderedItems.forEach(function(item){
                if(item && item.metric_number && item.metric_number < allItems.length){
                    var toFind = item.metric_number+1;
                    curItems.forEach(function(curItem){
                        if(curItem && curItem.metric_number && curItem.metric_number == toFind){
                            curItem.metric_number = item.metric_number;
                        }
                    })
                    item.metric_number++;
                }
            });
            setAllItems(curItems);
            setModalVisible(false);
            setSaveDisabled(false);
        }
    }

    function moveTableMetric(spot){
        if(selectedItems && selectedItems.length >= 1 && selectedItems[0] && allItems){
            if(spot <= 0){
                setModalErrorMessage("Position to move to cannot be below or equal to 0");
                return;
            }
            if(spot > allItems.length){
                setModalErrorMessage(`Position to move to cannot be outside the current maximum spot (${allItems.length})`)
                return;
            }
            // In case there's a call to move in between the selected items, move up the items to the bottom of the specified spots and then move down the items to the top of the specified spots
            var moveDownItems:ReportItem[] = []
            var moveUpItems: ReportItem[] = []
            selectedItems.forEach(function(item){
                if(item.metric_number && item.metric_number > spot)
                    moveUpItems.push(item);
                else
                    moveDownItems.push(item);
            });
            moveUpItems.sort((a,b) => {
                if(a.metric_number && b.metric_number){
                    return a.metric_number - b.metric_number;
                }
                return 0;
            });
            moveDownItems.sort((a,b) => {
                if(a.metric_number && b.metric_number){
                    return b.metric_number - a.metric_number;
                }
                return 0;
            });

            var curItems = getItems();
            var startSpot = spot;
            var endSpot = spot+selectedItems.length-1;
            spot += moveDownItems.length
            moveUpItems.forEach(function(selectedItem){
                var cur_metric_num = selectedItem.metric_number as number;
                curItems.forEach(function(item){
                    if(item && item.metric_number){
                        if(item.metric_number == cur_metric_num){
                            item.metric_number = spot;
                        }
                        else if(cur_metric_num > spot && item.metric_number >= spot && item.metric_number < cur_metric_num){
                            item.metric_number += 1;
                        }
                        else if(cur_metric_num < spot && item.metric_number <= spot && item.metric_number > cur_metric_num){
                            item.metric_number -= 1;
                        }
                    }
                })
                spot+=1;
            })
            spot = endSpot-moveUpItems.length;
            moveDownItems.forEach(function(selectedItem){
                            var cur_metric_num = selectedItem.metric_number as number;
                            curItems.forEach(function(item){
                                if(item && item.metric_number){
                                    if(item.metric_number == cur_metric_num){
                                        item.metric_number = spot;
                                    }
                                    else if(cur_metric_num > spot && item.metric_number >= spot && item.metric_number < cur_metric_num){
                                        item.metric_number += 1;
                                    }
                                    else if(cur_metric_num < spot && item.metric_number <= spot && item.metric_number > cur_metric_num){
                                        item.metric_number -= 1;
                                    }
                                }
                            })
                            spot-=1;
                        })
            setAllItems(curItems);
            setModalVisible(false);
            setSaveDisabled(false);
        }
        else{
            setModalErrorMessage('No object was selected')
        }
    }

    function getOrder(){
        var curItems = allItems as ReportItem[];
        curItems.sort((n1,n2)=> {
            if(!n1.metric_number || !n2.metric_number || n1.metric_number == n2.metric_number){
                return 0;
            }
            else if(n1.metric_number > n2.metric_number){
                return 1;
            }
            else{
                return -1;
            }
        });
        var output: string[] = [];
        curItems.forEach(function(item){
            output.push(item.id);
        })
        return output;
    }

    function showDeleteItemsModal(items) {
        if (items.length > 0){
            setDeleteModalVisible(true);
        }
    }

    async function deleteSelectedItems() {
        await getMidwayJwtToken();
        if (!!selectedItems){
            // Check reason
            if(deleteMetricReason.length === 0){
                // Show error
                setDeleteReasonTextInvalid(true);
                return;
            }

            // Everything is ready to delete
            setDeleteReasonTextInvalid(false);
            setDeleteButtonDisabled(true);
            setCancelButtonDisabled(true);

            let deleteProgressPercentage = 0;
            let deleteProgressDelta = 100/selectedItems.length;
            let successCount = 0;
            for (let i=0; i < selectedItems.length; i++){
                setDeleteProgressInfo("Deleting '" + selectedItems[i].name + "'");

                const deleteReportItemObj: DisableReportItemRequest = {
                    message: deleteMetricReason
                };

                // Calling updateReportItem
                await FondueApi.deleteReportItem(selectedItems[i].id)
                    .then((response) => {
                        deleteProgressPercentage += deleteProgressDelta;
                        setDeleteProgressPercentage(deleteProgressPercentage);
                        successCount +=1
                    })
                    .catch((e)=> {
                        // Show error and stop deleting items
                        setDeleteProgressInfo('Failed to delete ' + selectedItems[i].name);
                        return;
                    });
            }
            if (successCount === selectedItems.length){
                setDeleteProgressInfo(constants.ALL_REPORT_ITEMS_DELETION_SUCCESS);
                // Show info box
                setAddDisabled(true);
                setEditDisabled(true);
                setAddEditInfoDisabled(false);
            }
            setCancelButtonDisabled(false);
        }
    }

    async function dismissDeleteModal(){
        // Reset modal
        setDeleteModalVisible(false);
        setDeleteProgressPercentage(0);
        setDeleteMetricReason("");
        setDeleteProgressInfo("");
        setDeleteButtonDisabled(false);
        setCancelButtonDisabled(false);
        setRefreshTable(true);
        setState(States.success);
    }

    function metricItemAction(event){
        switch (event.detail.id) {
            case "mv":
                togglePopup();
                break;
            case "reset":
                var curItems = getItems();
                for (let i = 0; i < curItems.length; i++) {
                    if(curItems[i] && curItems[i].metric_number && initialItems && initialItems[i]){
                        curItems[i].metric_number = initialItems[i];
                    }
                }
                setAllItems(curItems);
                setSaveDisabled(true);
                break;
            case "del":
                showDeleteItemsModal(selectedItems);
                break;
        }
    }

    function ellipsesMetricItemAction(id: string, item: ReportItem){
        switch (id) {
            case "da":
                // Duplicating above we insert New Item at Existing Item row #
                createReportItem(item, Number(item.metric_number))
                break
            case "db":
                // Duplicating below we insert New Item at existing Item Row # + 1
                var decrementIndex = Number(item.metric_number) + 1
                createReportItem(item, decrementIndex)
                break
        }
    }

    function togglePopup(){
        setModalErrorMessage('')
        setModalVisible(true);
    }


    async function listReportMetrics(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.listReportMetrics(report_id)
                .then(async(response) => {
                    setSelectedItems([]);
                    setAllItems(response.data['results']);
                    setState(States.success)

                    var items:  number[] = []
                    response.data['results'].forEach(function(item){
                        if(item.metric_number)
                            items.push(item.metric_number);
                     })
                    setInitialItems(items);

                    // Set All ReportItems is passed in, used by other components EditMetricForm
                    setAllReportItems(response.data['results']);

                    // Enable Add Item Button
                    setDisableAddItem(false);
                })
                .catch((e) => {
                    setState(States.error)
                    listReportMetrics(FondueApi, counter + 1)
                })
        }
    }



    async function createReportItem(reportItemData: {}, metric_number: number) {

        var itemType = reportItemData!['type'] || ""

        const reportItem: ReportItem = {
            id: '',
            // By default naming to existing Item Name + copy
            name: reportItemData['name'] + " copy"|| "",
            type: itemType,
            report_id: report.id,
            bindle: report.bindle,
            report_item_config: {
                ...reportItemData!['report_item_config'],
            },
            description: reportItemData['description'] || '',
            metric_number: metric_number
        };

        // Add additional fields to based on item type
        if(itemType !== 'header'){
            reportItem['query'] = reportItemData['query'] || "";

            // Add format for multiple result type
            if(itemType === 'query_multiple_result'){
                reportItem['format'] =reportItemData['format'] || "";
            }
        }

        // Disabling ActionEllipses Button Dropdown to avoid submitting multiple actions at once
        setActionEllipsesDisabled(true);
        setState(States.submitting)
        // Disable Add Item while Creating report item
        setDisableAddItem(true);

        // Clearing out any SelectedItems, after Creation we will select newly Created Item by default
        setSelectedItems([]);
        setAddEditInfoDisabled(false);
        setSelectedItems([]);
        setAddDisabled(true);
        setEditDisabled(true);

        const FondueApi = FondueApiFactory();
        // Calling createReportItem
        await getMidwayJwtToken();
        await FondueApi.createReportItem(reportItem)
            .then((response) => {
                // Set page to loading report
                setState(States.loading);

                // Setting refreshTable to true
                setRefreshTable(true);

                // Setting Pre Selected item to newly created Report Item
                setPreSelectedItem(reportItem);
            })
            .catch((e)=> {
                // Set State to failedCreate
                setState(States.failedCreate)

            });

    }

    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();

            // Call listReportsMetrics which will retry up to 3 times
            await listReportMetrics(FondueApi, 0)

            // Setting refreshTable to false so additional updated to EditMetricForm can be picked up
            setRefreshTable(false);

            // Re-enabling Action Ellipses Button Dropdown after Table load 
            setActionEllipsesDisabled(false);
        })();
    }, [refreshTable, FondueApiFactory]);

    useEffect (() => {
        if(updatingReportItem == 'WAIT' && refreshTable==false){
            setUpdatingReportItem('DONE')
        }

        // Checking if PreSelectedItem needs to be checked
        if(preSelectedItem){

            // var editedItem = allItems?.filter(item => item.metric_number === keepSelectedItem!.metric_number)
            var showItem = allItems?.filter(item => item.metric_number === preSelectedItem!.metric_number)

            if (showItem?.length == 1) {
                // We re-save PreSelected Item with item_id we get from list_report_metrics call
                setPreSelectedItem(showItem[0]);
                var detail = {'selectedItems': showItem}
                handleSelect(detail)
            };
        }
    }, [allItems])

    useEffect ( () => {
        if(editSelectReportItem) {
            var editedItem = allItems?.filter(item => item.id === editSelectReportItem)
            if(editedItem?.length == 1){
                var detail = {'selectedItems': editedItem}
                handleSelect(detail)
                setEditSelectReportItem('')
            }
        }
    }, [editSelectReportItem])

    const { items,  filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                )
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: { defaultState: { sortingColumn: { sortingField: "metric_number" } } },
            selection: {}
        }
    );

    function cancel(){
        setModalVisible(false);
    }

    function moveMetric(){
        moveTableMetric(parseInt(inputValue))
    }

    function savePlacements(){
        setState(States.savingReport)
        setSaveDisabled(true)
        getMidwayJwtToken();
        var updatedReport: Report = report
        updatedReport["report_item_order"] = getOrder()
        const fondueApi = FondueApiFactory();

        fondueApi.updateReport(report_id, updatedReport)
            .then((response) => {
                setState(States.saveReportSuccess)
            })
            .catch((e) => {
                return;
            })
        var items:  number[] = []
        getItems().forEach(function(item){
            if(item.metric_number)
                items.push(item.metric_number);
        })
        setInitialItems(items);
    }

    function handlePagination(event) {
    }

    function handleSelect(detail) {

        setSelectedItems(detail.selectedItems);
        if (detail.selectedItems.length === 1){
            const selecteditem = detail.selectedItems[0];
            const reportItem: ReportItem = {
                id: selecteditem.id,
                name: selecteditem.name,
                type: selecteditem.type,
                report_id: selecteditem.report_id,
                description: (selecteditem.description) ? (selecteditem.description) : "",
                report_item_config: selecteditem.report_item_config,
                query: selecteditem.query,
                format: (selecteditem.format) ? (selecteditem.format) : "",
                bindle: selecteditem.bindle,
                metric_number: (selecteditem.metric_number) ? (selecteditem.metric_number) : 0,
            };
            setReportItem(reportItem);
            setSelectedItem(detail.selectedItems);
            setAddEditInfoDisabled(true);
            setAddDisabled(true);
            setEditDisabled(false);
            // Record the time the user starts editing a report item (opens a report item component)
            // If the user opens an item but does not perform edit save, the time is recorded but not used
            setReportItemEditStart(Date.now());

            // Resetting PreSelectedItem to null if currently selected Item already matches
            // This is to avoid any issue in defaulting back to PreSelectedItem after duplicate / select already performed
            if(preSelectedItem && preSelectedItem!.id == reportItem.id){
                setPreSelectedItem(undefined);
            }
            
        }
        else {
            // Reset preselectedItem if 0 or more items selected in HandleSelect
            setPreSelectedItem(undefined);
            // 0, 2 or more items are selected
            setAddEditInfoDisabled(false);
            setAddDisabled(true);
            setEditDisabled(true);

        }

    }

    const defaultSort: TableProps.SortingColumn<ReportItem> = {
      sortingField: "metric_number",
    }

    //const { selectedItems } = collectionProps;


    function showAddMetricComponent(){
        // Record the time the user starts adding a report item (opens a report item component)
        // If the user starts adding an item but does not perform save, the time is recorded but not used
        setReportItemEditStart(Date.now());

        setAddDisabled(false);
        setEditDisabled(true);
        setAddEditInfoDisabled(true);
        setSplitPanelOpen(true);
        setSplitPanelTitle(constants.ADD_REPORT_ITEM_LABEL)
        setSelectedItems([]);
    }

    const addMetricButton = (
        <Button variant="primary" onClick={showAddMetricComponent} id='add-metric-button' disabled={disableAddItem}>
            Add Item
        </Button>
    );

    const actionsButton = (
        <ButtonDropdown
            id ="btnDropdown"
            items={[
                { text: "Edit", id: "ed", disabled: !selectedItems || selectedItems.length != 1 },
                { text: "Delete", id: "del", disabled: false },
                { text: "Move to row #", id: "mv", disabled: false },
                { text: "Reset order", id: "reset", disabled: false }
            ]}
            onItemClick={(event) => metricItemAction(event)}
        >
            Actions
        </ButtonDropdown>
    );


    const moveUpButton = (
        <Button id="moveUp" onClick={(event) => moveMetricUp()}>Move Up</Button>
    )
    const moveDownButton = (
        <Button id="moveDown" onClick={(event) => moveMetricDown()}>Move Down</Button>
    )
    const savePlacementButton = (
        <Button id="savePlacement" variant="primary" disabled={saveDisabled} onClick={(event) => savePlacements()}>Save </Button>
    )

    return (
      <div>
        <Table
            {...collectionProps}
            loading={!allItems && state === States.loading}
            loadingText="Loading instances"
            selectionType="multi"
            selectedItems={selectedItems}
            header={
                <Header
                    counter={
                        allItems &&
                        (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            {actionsButton}
                            {moveUpButton}
                            {moveDownButton}
                            {savePlacementButton}
                            {addMetricButton}
                        </SpaceBetween>
                    }
                >
                    Items
                </Header>
            }
            columnDefinitions={[
                {
                    id: 'metric_number',
                    header: '#',
                    cell: item => item.metric_number,
                    width: '80px',
                    sortingField: 'metric_number'
                },
                {
                    id: 'name',
                    cell: item  => item.name,
                    header: 'Report item name',
                    minWidth: '160px',
                    sortingField: 'name'
                },
                {
                    id: 'type',
                    cell: item => item.type,
                    header: 'Report item type',
                    minWidth: '160px',
                    sortingField: 'type'
                },
                {
                    id: 'description',
                    header: 'Description',
                    cell: item => item.description || "-",
                    minWidth: '180px'
                },
                {
                    id: 'id',
                    header: 'Report item id',
                    cell: item => item.id || "-",
                    minWidth: '180px'
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    cell: item =>  (
                        <ButtonDropdown
                            id ="actionEllipsesDropdown"
                            variant="inline-icon"
                            items={[
                                { text: "Duplicate above", id: "da", disabled: false },
                                { text: "Duplicate below", id: "db", disabled: false },
                            ]}
                            onItemClick={(event) => ellipsesMetricItemAction(event.detail.id, item)}
                            expandToViewport={true}
                            disabled={actionEllipsesDisabled}
                        >
                            Actions
                        </ButtonDropdown>
                    ),
                    width: 130,
                    minWidth: 100,
                    isRowHeader: true
                },
            ]}
            visibleColumns={preferences.visibleContent}
            resizableColumns
            items={items}
            stickyColumns={{ last: 1}}
            onSelectionChange={({ detail }) => handleSelect(detail)}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}
                onChange={ event  => {
                    handlePagination(event)
                    paginationProps.onChange(event);
                }}
            />}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                    filteringPlaceholder='Find report items'
                />
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={preferences}
                    pageSizePreference={{
                        title: "Select page size",
                            options: [
                            { value: 20, label: "20 items" },
                            { value: 50, label: "50 items" },
                            { value: 100, label: "100 items" }
                        ]
                    }}
                    onConfirm={({ detail }) => {
                        setPreferences(detail);
                        setPageSize(detail.pageSize as number);
                    }}
                    visibleContentPreference={{
                        title: "Preferences",
                        options: [
                            {
                                label: "Available columns",
                                options: [
                                    { id: "metric_number", label: "Metric number" },
                                    { id: "name", label: "Name" },
                                    { id: "type", label: "Report item type" },
                                    { id: "description", label: "Description" },
                                    { id: "id", label: "Report item id"},
                                    { id: "actions", label: "Actions", editable: false}
                                ]
                            }
                        ]
                    }}
                />
            }
        />
        <Modal
          id ="moveModal"
          onDismiss={() => setModalVisible(false)}
          visible={modalVisible}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={(event) => cancel()}>Cancel
                </Button>
                <Button id = "confirm" variant="primary" onClick={(event) => moveMetric()}>Ok</Button>
              </SpaceBetween>
            </Box>
          }

        >
        <FormField
              id = "reorderFormField"
              description="Position to move item."
              label="Reorder Item"
              errorText={modalErrorMessage}
            >
          <Input
            id="reorderInput"
            value={inputValue}
            onChange={event =>
              setInputValue(event.detail.value)
            }
            type="number"
          />
          </FormField>
        </Modal>
          <Modal
              id ="deleteMetricModal"
              onDismiss={() => dismissDeleteModal()}
              visible={deleteModalVisible}
              closeAriaLabel="Close modal"
              header="Delete Item"
              footer={
                  <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="normal" onClick={(event) => dismissDeleteModal()} disabled={cancelButtonDisabled}>Cancel</Button>
                          <Button id = "confirm" variant="primary" onClick={(event) => deleteSelectedItems()} disabled={deleteButtonDisabled}>Delete</Button>
                      </SpaceBetween>
                  </Box>
              }>
            <div>
                <Alert type="warning">
                    {constants.RESTORE_DELETED_ITEMS_WARNING}
                    <br/>Please cut ticket to 'AWS/Auth/AWSID Business Metrics' CTI to restore items.
                </Alert>
                <p>Please enter a reason to delete following item(s):</p>
                <SpaceBetween direction="vertical" size="m">
                <ol>
                    {selectedItems?.map(item => (
                        <li key={item.id}>
                            {item.name} <Badge>{(item.type === 'query_single_result' || item.type === 'query_multiple_result') ? 'query' : item.type}</Badge>
                        </li>
                    ))}
                </ol>
                    <Textarea
                        onChange={({ detail }) => setDeleteMetricReason(detail.value)}
                        value={deleteMetricReason}
                        placeholder="Please enter a valid reason"
                        invalid={deleteReasonTextInvalid}
                    />
                <ProgressBar
                    value={deleteProgressPercentage}
                    label={constants.DELETE_PROGRESS_LABEL}
                    additionalInfo={deleteProgressInfo}/>
                </SpaceBetween>
            </div>
          </Modal>
        </div>
    );
}
