import { Route, Switch, useLocation } from 'react-router-dom';
import BasicLayout from './basic';
import ServiceHomepage from './home';
import CreateForm from './create';
import TableView from './table';
import ReportsTableView from './reports';
import ReportsFormView from "./reportform";
import ReportItemsTableView from "./reportitems";
import EditReportsFormView from "./editreport";
import ReportResultView from "./viewreportresult";
import RunReportView from "./runreport";
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import { useEffect, useRef, useState } from 'react';
import { Icon, Toggle } from "@amzn/awsui-components-react/polaris";
import { useCookies } from 'react-cookie';
import {useData} from './DataContext';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { recordLatencyTimes } from "../util/client_metrics/MetricHandlers";
import { pathToName } from "../util/client_metrics/MetricUtils";
// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
export default function App() {
  const i18nStrings = {
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'All',
  };
  const {setDarkMode} = useData();
  const [cookies, setCookie] = useCookies(['darkmode']);
  const [isDarkMode, setIsDarkMode] = useState(cookies.darkmode === 'true');
  useEffect(() => {
    document.body.classList.toggle('awsui-polaris-dark-mode', isDarkMode);
    setCookie('darkmode', isDarkMode);
    setDarkMode(isDarkMode);
  }, [isDarkMode, setCookie]);

  // Use useLocation to track route navigation
  const location = useLocation();
  const {metricsDriver, pageState} = useData();

  // Create performance observer to track client side latency from performance marks
  const metricObserver = useRef(new PerformanceObserver((entries, obj) => {recordLatencyTimes(entries, pageState, metricsDriver)}));
  metricObserver.current.observe({entryTypes: ["mark"]});

  // On route change, record current page and time to help calculate page load latency
  useEffect(() => {
    pageState.navigationTime = Date.now();
    pageState.name = pathToName(location.pathname);
  }, [location]);

  return (
    <div>
        <div id='topNavigation' style={{position: "fixed",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 1000}}>
                <TopNavigation identity={{
                    href: "#",
                    title: "Fondue Reporting",
                }}
                  i18nStrings={i18nStrings}
                  disableBodyScroll={true}
                  utilities={[
                    {
                        type: "button",
                        // @ts-ignore
                        text: <Toggle
                                onChange={() => {
                                    setIsDarkMode(!isDarkMode);
                                }}
                                checked={isDarkMode}
                            >
                                <Icon
                                  svg={
                                      <span>
                                          <svg
                                              height="15"
                                              width="15"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 384 512"
                                              fill={awsui.colorTextInteractiveHover}>
                                              <path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z" />
                                          </svg>
                                      </span>
                                  }
                              />
                            </Toggle>,
                        ariaLabel: 'Toggle dark mode',
                        disableUtilityCollapse: true
                    }
                    ]}
                    />
        </div>
        {/* Below div is to compensate the top navigator height  */}
        <div style={{ height: '56px' }}></div>
        <Switch>
              <Route exact path="/" component={ServiceHomepage} />
              <Route path="/basic" component={BasicLayout} />
              <Route path="/create" component={CreateForm} />
              <Route path="/table" component={TableView} />
              <Route exact path="/reports" component={ReportsTableView} />
              <Route exact path="/reports/create" component={ReportsFormView} />
              <Route exact path="/reports/:report_id" component={ReportItemsTableView} />
              <Route exact path="/reports/:report_id/edit" component={EditReportsFormView} />
              <Route exact path="/reports/:report_id/result" component={ReportResultView} />
              <Route exact path="/reports/:report_id/runreport" component={RunReportView} />
          </Switch>
    </div>
  );
}
