import React, {createContext, useContext, useState} from 'react';
import {ReportItem} from "../fondue-api/generated-src";
import MetricsDriver from "../util/client_metrics/MetricsDriver";
import { PageState } from "../util/client_metrics/MetricUtils";
interface DataContextType{
    data: {} | null;
    setData: React.Dispatch<React.SetStateAction<{} | null>>;
    darkMode: boolean;
    setDarkMode: React.Dispatch<React.SetStateAction< boolean>>;
    rerunNonFinishedMetricsWBRDate: string;
    setRerunNonFinishedMetricsWBRDate: React.Dispatch<React.SetStateAction<string>>
    preSelectedItem: ReportItem | undefined
    setPreSelectedItem: React.Dispatch<React.SetStateAction<ReportItem | undefined>>
    metricsDriver: MetricsDriver;
    pageState: PageState;
    reportItemEditStart: number;
    setReportItemEditStart: React.Dispatch<React.SetStateAction<number>>

}
const DataContext = createContext<DataContextType | undefined>(undefined);
export const DataProvider: React.FC = ({children}) => {
    const [data, setData] = useState<{} | null>(null);
    const [darkMode, setDarkMode] = useState<true | false>(false);
    const [rerunNonFinishedMetricsWBRDate, setRerunNonFinishedMetricsWBRDate] = useState('');
    const [preSelectedItem, setPreSelectedItem] = useState<ReportItem | undefined>();
    const [metricsDriver] = useState<MetricsDriver>(new MetricsDriver());
    const [pageState] = useState<PageState>({
        name: "",
        navigationTime: 0,
    })
    const [reportItemEditStart, setReportItemEditStart] = useState<number>(0);

    return (
        <DataContext.Provider value={{data, setData, darkMode, setDarkMode,
         rerunNonFinishedMetricsWBRDate, setRerunNonFinishedMetricsWBRDate, preSelectedItem, setPreSelectedItem,
         metricsDriver, pageState, reportItemEditStart, setReportItemEditStart}}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if(!context) {
        throw new Error('useData must be used within Data provider.')
    }
    return context;
}
