import {
    INVALID_PAGE,
    PATH_EXACT_MATCH,
    PATH_PATTERN_MATCH,
    PATH_REGEXP,
    REPORT_ITEMS_PAGE,
} from "./Constants";


// Track navigation time of the current page to help calculate latency
export interface PageState {
    name: string;
    navigationTime: number;
}


// Get page name using page path from location.pathname
export function pathToName(pagePath: string) {
    // Reports and create report pages are exact path matches
    const page = PATH_EXACT_MATCH.get(pagePath);
    if (page) {
        return page;
    }

    // Check for '/reports/:report_id' at the beginning of the path
    // and extract an optional path segment at the end
    const pathRE = PATH_REGEXP.exec(pagePath);
    if (pathRE && pathRE["groups"]) {
        const { endSegment } = pathRE["groups"];

        // Report Items page has path "/reports/:report_id" so endSegment will be undefined
        if (endSegment === undefined) {
            return REPORT_ITEMS_PAGE
        }
        return PATH_PATTERN_MATCH.get(endSegment) || INVALID_PAGE;
    }

    return INVALID_PAGE;
}

