import {
    CREATE_REPORT_PAGE,
    EDIT_REPORT_PAGE,
    REPORT_ITEMS_PAGE, REPORTS_PAGE,
    RUN_HISTORY_PAGE,
    RUN_REPORT_PAGE
} from "./Constants";
import MetricsDriver from "./MetricsDriver";
import { PageState } from "./MetricUtils";


// Helper function for capturing page load latencies
// Calculate and send latency metric and update pageState with the current page
const recordLatencyTimesHelper = (entry: PerformanceEntry, pageName: string, navigationTime:number, metricsDriver: MetricsDriver) => {
    // Entry startTime is offset from timeOrigin, add timeOrigin to get the timestamp when the page is loaded
    const loadTime = performance.timeOrigin + entry.startTime;
    const latency = Math.round( loadTime - navigationTime );
    metricsDriver.sendPageLatency(latency, pageName);
}

// Function to handle PerformanceMark received from PerformanceObserver
// Used for capturing page load latencies
export const recordLatencyTimes = (entries: PerformanceObserverEntryList, pageState: PageState, metricsDriver: MetricsDriver) => {
    entries.getEntries().forEach((entry) => {

        const pageName = pageState.name;
        // For Report Result, Report Items, and Reports pages, page load completes at the "tableUpdated" mark
        if (entry.name === "tableUpdated") {
            if (pageName === RUN_HISTORY_PAGE || pageName === REPORT_ITEMS_PAGE || pageName === REPORTS_PAGE) {
                recordLatencyTimesHelper(entry, pageName, pageState.navigationTime, metricsDriver);
            }
        }
        // For Create Report, Run Report, and Edit Report pages, page load completes at the "primaryButtonRendered" mark and does not have table update
        else if (entry.name === "primaryButtonRendered") {
            if (pageName === CREATE_REPORT_PAGE || pageName === RUN_REPORT_PAGE || pageName === EDIT_REPORT_PAGE) {
                recordLatencyTimesHelper(entry, pageName, pageState.navigationTime, metricsDriver);
            }
        }
    })
}

